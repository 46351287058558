@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Pathway+Gothic+One&display=swap');


body{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Pathway Gothic One', sans-serif;
  background-color: #B5CDF0;
  height: 100vh;
}

.slide-in{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #B11F54;
  transform-origin: bottom;
}

.slide-out{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #B11F54;
  transform-origin: top;
}