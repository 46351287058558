.blog {
    margin-top: 50px;
    width: 90%;
  }
  
  .blog .category-slider {
    width: 100%;
  }
  
  
  .blog .boxItems:hover {
    box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
    cursor: pointer;
    transform: translateY(-5px);
  }
  
  .blog img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    margin-bottom: 20px;
  }
  
  .blog .tag {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
  
  .blog .icon {
    margin-right: 10px;
    color: #B11F54;
    font-size: 20px;
  }
  
  .blog .tag a {
    font-size: 100%;
    color: #B11F54;
    opacity: 0.7;
  }
  
  .blog h3 {
    width: 80%;
    font-size: 100%;
    font-weight: 700;
    font-family: 'Libre Baskerville', serif;
    margin: 0;
    line-height: 100%;
    color: #B11F54;
  }
  
  .blog .link {
    color: #B11F54;
    font-size: 1.5em;
  }
  
  .linkCopied {
    font-family: 'Pathway Gothic One', sans-serif;
    padding: 0% 10%;
    margin-left: 5%;
    border: none;
    background-color: #b8cdec;
  }
  
  .linkCopied label {
    margin-right: 0;
  }
  
  .urlMessage {
    margin-left: 2%;
  }
  
  .blog p {
    font-family: 'Libre Baskerville', serif;
    color: #B11F54;
    font-weight: 400;
    margin: 20px 0;
    font-size: 100%;
  }
  
  .blog .date {
    color: #B11F54;
    display: flex;
    align-items: center;
  }
  
  .blog .date .icon {
    margin-right: 0%;
  }
  
  .blog .date label {
    display: block;
  }
  
  .container {
    width: 100%;
  }
  