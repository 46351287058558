.category {
    margin: 0%;
    position: relative;
    width: 90%;
    left: 5%;
  }
  .category .box {
    border-radius: 0;
    text-align: center;
  }
  .category p {
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 600;
  }
  .category img {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  .category .box::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 250px;
    background: rgba(0, 0, 0, 0.622);
  }
  .category .box {
    cursor: pointer;
    position: relative;
  }
  .category .overlay {
    position: absolute;
    top: 20%;
    text-align: center;
    width: 100%;
    z-index: 2;
    color: #fff;
  }
  .category .overlay h4 {
    font-family: 'Abril Fatface', sans-serif;
    font-weight: 400;
    font-size: 200%;
    color: #ffffff;
    margin-bottom: -1em;
  }
  .category .overlay p {
    color: rgb(252, 200, 225);
    font-weight: 300;
    font-size: 100%;
    padding: 15px;
  }
  /*---------------control-btn-------------*/
  .control-btn button {
    color: #B11F54;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    padding: 2px;
    background: #B5CDF0;
    border:none;
  }

  .control-btn :hover{
    color: #680b2d;
  }
  
  
  .control-btn .next {
    position: absolute;
    top: 50%;
    right: -4.5%;
  }
  
  .control-btn .prev {
    position: absolute;
    top: 50%;
    left: -4.5%;
    z-index: 99;
  }
  .control-btn .icon {
    font-size: 25px;
  }
  /*---------------control-btn-------------*/