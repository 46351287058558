@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Libre+Baskerville:ital@0;1&family=Pathway+Gothic+One&display=swap');

/* TITLE */

#folkliftTitle{
    color:#B11F54;
    
}

.top-part h1{
    font-family: 'Abril Fatface',sans-serif;
    font-weight: 400;
    max-width: 100%;
    margin-left: auto;
    padding: 0;
    color: #1e1e1e;
    font-size: 500%;
    
    margin: 0;
    margin-top: 2%;
    text-align: center;
}

/* LIST FORMATTING */

.top-part ul{
    list-style: none;
    padding: 0;
    width: 80%;
    margin-right: 5%;
    margin-left: 10%;
    text-align: center;
}
.top-part li{
    display: inline-block;
}

.top-part a{
    text-decoration: none;
    width: 100%;
    display: block;
    color: #1e1e1e;
    font-size: 1.25rem;
    font-family: 'Pathway Gothic One', sans-serif;
    padding: 0em 0.5em;
    text-transform: uppercase;
    text-align: center;
}

.top-part a:hover{
    color: #B11F54;
}

.top-part hr{
    margin-bottom: 0;
    width: 90%;
    border: .5px solid #1E1E1E;
}

header .active{
    position: sticky;
    top: 0;left: 0;width: 100%;
}

@media(max-width:445px){
    .top-part h1{
        font-size: 300%;
    }
}