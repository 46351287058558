.singlePage {
  margin-bottom: 15%;
}

.containerDetails {
  width: 100%;
  background: #A4BEE5;
  margin-top: 2%;
  margin-bottom: 5%;
}

.ContactInfo {
  text-align: center;
  background-color: #92aed8;
  padding: 5%;
  margin-bottom: 5%;
}

.ContactInfo h4 {
  font-family: 'Libre Baskerville';
  font-weight: 400;
  margin: 0;
  font-size: 1em; /* Base font size */
}

.ContactInfo ul {
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  list-style: none;
}

.ContactInfo li {
  max-width: 100%;
  font-size: 1.5em; /* Base font size */
}

.ContactInfo li a {
  max-width: 50%;
  font-size: 1.5em; /* Base font size */
}

.singlePage .writerInfo {
  font-family: 'Libre Baskerville', sans-serif;
  font-size: 1.5em; /* Base font size */
  text-transform: capitalize;
  width: 90%;
  margin-left: 5%;
  line-height: 1.2;
  text-align: right;
  color: #B11F54;
}

.singlePage hr {
  border: 0.5px solid #1E1E1E;
}

.singlePage img {
  width: 90%;
  margin-left: 5%;
  max-height: 20em;
  object-fit: cover;
}

.singlePage h1 {
  font-family: 'Abril Fatface', sans-serif;
  font-weight: 500;
  color: #B11F54;
  max-width: 100%;
  margin-bottom: 0;
  margin-top: 2%;
  font-size: 2.5em; /* Base font size */
}

.singlePage p {
  margin: 20px 0;
  font-size: 1em;
  line-height: 2;
  font-family: "Libre Baskerville", sans-serif;
}

.singlePage .left {
  width: 90%;
  margin-left: 5%;
}

.singlePage .left h3 {
  margin: 0;
  margin-top: 0;
  font-weight: 400;
  font-size: 1.5em; /* Base font size */
}

.singlePage .buttons {
  display: flex;
  justify-content: flex-end;
}

.singlePage .button {
  margin-left: 20px;
  border: none;
}

.singlePage .button:nth-child(1) {
  background: #10ac84;
}

.singlePage .button:nth-child(2) {
  background: #ee5253;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .ContactInfo h4 {
    font-size: 4vw; /* Responsive font size */
  }

  .ContactInfo li {
    font-size: 4vw; /* Responsive font size */
  }

  .ContactInfo li a {
    font-size: 4vw; /* Responsive font size */
  }

  .singlePage .writerInfo {
    font-size: 4vw; /* Responsive font size */
  }

  .singlePage .writerInfo h3 {
    font-size: 4vw; /* Responsive font size */
  }

  .singlePage h1 {
    font-size: 7vw; /* Responsive font size */
  }

  .singlePage .left h3 {
    font-size: 4vw; /* Responsive font size */
  }
}
