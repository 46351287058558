.aboutUs section{
    grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
}
.ourStory{
    border-right: 1px solid #1E1E1E;
}

.ourStory h1{
    font-family: 'Libre Baskerville', sans;
    font-weight: 700;
    font-size: 300%;
    margin: 0;
}
.ourStory p{
    text-align: justify;
    font-family: 'Libre Baskerville', sans;
    font-size: 1em;
    margin: 0 10% 0 0;
}
.contact h2{
    font-size: 2em;
    font-family: 'Libre Baskerville', sans;
    margin: 0;
}

.contact ul{
    list-style-type:none;
    margin-top: 10px;
}
.contact a{
    font-size: 100%;
    text-decoration: none;
    color: #4A5F7D;
    font-family: 'Libre Baskerville', sans;
}

.contact{
    width: 90%;
    color: #4A5F7D;
    margin-left: 10%;
    margin-top: 10%;
    right: 0;
}

@media(max-width:555px){
    .ourStory{
        border-right: none;
        border-bottom:#1E1E1E 1px solid;
    }
    .ourStory p{
        margin-bottom: 5%;
    }
}