/* Card.css */

.blog {
  margin-top: 50px;
  width: 90%;
}

.blog .boxItems {
  margin: 2%; /* Adjust the margin as needed */
}

.blog .boxItems:hover {
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
  cursor: pointer;
  transform: translateY(-5px);
}

.slick-dots {
  display: block !important;
}

.blog .category-slider {
  width: 100%;
  margin-bottom: 20px; /* Add margin between category sliders */
}

.blog .box {
  position: relative;
  width: calc(100% - 2em); /* Adjust the width considering the total margin (2em) */
  height: 38em;
  background: #A4BEE5;
  padding: 1em;
  transition: 0.5s;
  box-sizing: border-box; /* Include padding and border in the total width and height */
}

.blog img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  margin-bottom: 20px;
}

.control-btn button {
  color: #B11F54;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  padding: 2px;
  background: #B5CDF0;
  border:none;
}

.control-btn :hover{
  color: #680b2d;
}


.control-btn .next {
  
  position: absolute;
  top: 50%;
  right: -4.5%;
}

.category-slider h2{
  margin-left: 1%;
  color:#1E1E1E;
  font-family: 'Libre Baskerville', serif;
}

.category-slider hr{
  width: 100%;
  border: .5px solid #8fb0e2;
}

@media (max-width:600px) {
  .blog .box{
    height: 33em;
  }
}

.control-btn .prev {
  position: absolute;
  top: 50%;
  left: -4.5%;
  z-index: 99;
}
.control-btn .icon {
  font-size: 25px;
}
